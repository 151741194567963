import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import Image from "next/image";

export default function Testimonialcard({ avatar, name, role, rating }) {
  const mainTab = useSelector((state) => state.mainTab.mainTab);
  const isCompanyCheck = mainTab === "شركات";
  return (
    <Box
      sx={{
        width: { xs: "220px", sm: "380px" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "300",
        color: "#040921",
        borderRadius: "32px",
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
        padding: "24px",
        overflow: "hidden",
        bgcolor: "#fff",
      }}
    >
      <Image
        alt="testimonials"
        src={avatar}
        width={60}
        height={60}
        style={{ height: "60px", width: "60px", borderRadius: "50%" }}
      />
      <Box sx={{ fontWeight: "600" }}>{name}</Box>
      <Box
        sx={{
          height: isCompanyCheck ? { xs: "50px", sm: "45px" } : "initial",
          fontSize: { xs: "14px", sm: "initial" },
        }}
      >
        {role}
      </Box>
      <Image width={60} height={10} alt="divider" src="/business/divider.svg" />

      <Box
        sx={{
          height: isCompanyCheck
            ? { xs: "300px", sm: "200px" }
            : { xs: "90px", sm: "60px" },

          fontSize: { xs: "14px", sm: "initial" },
        }}
      >
        {rating}
      </Box>

      <Image width={40} height={40} alt="comma" src="/business/comma.svg" />

      <Image
        width={105}
        height={5}
        alt="border"
        src="/business/border.svg"
        style={{ position: "relative", top: "24px", width: "105%" }}
      />
    </Box>
  );
}
